
import { computed, defineComponent, reactive, ref } from "vue";
import BaseFileUpload from "@/app/core/components/forms/BaseFileUpload.vue";
import BaseCard from "@/app/core/components/cards/BaseCard.vue";
import { McService } from "@/app/core/services/McService";
import { container } from "tsyringe";
import { helpers, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import SpinnerLoader from "@/app/core/components/SpinnerLoader.vue";

export default defineComponent({
  name: "McNotFound",
  components: { SpinnerLoader, BaseCard, BaseFileUpload },
  setup() {
    const toast = useToast();
    let isDataLoading = ref<boolean>(false);
    const mc = useRoute().query.mc as string;
    const mcService: McService = container.resolve(McService);
    let uploaded = ref<boolean>();
    const form = reactive<{ files: Blob[] }>({
      files: [],
    });

    const rules = {
      files: {
        required: helpers.withMessage("At least one document must be provided", required),
        minLength: helpers.withMessage("At least one document must be provided", minLength(1)),
      },
    };

    const v$ = useVuelidate(rules, form);

    const sendDocs = () => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        isDataLoading.value = true;
        toast.info("Uploading your files");
        const formData = new FormData();
        for (const file of form.files) {
          formData.append("files", file);
        }
        mcService.sendMc(mc, formData).then((response) => {
          if (response) {
            isDataLoading.value = false;
            toast.success("Successfully sent files");
            uploaded.value = true;
          }
        });
      }
    };

    return {
      isDataLoading,
      windowWidth: window.innerWidth,
      uploaded,
      form,
      v$,
      sendDocs,
    };
  },
});
