
import { computed, defineComponent, PropType, ref, watch } from "vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import { determineFileType } from "@/app/core/utils/determineFileType";

export default defineComponent({
  name: "BaseFileUpload",
  emits: ["update:files"],
  components: { BaseIcon },
  props: {
    title: {
      required: false,
      type: String,
    },
    sizeLimit: {
      required: true,
      type: Number,
    },
    multiple: {
      required: false,
      type: Boolean,
    },
    countMax: {
      required: false,
      type: Number,
    },
    files: {
      required: true,
      type: Object as PropType<Array<Blob> | Blob>,
    },
    errors: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
  },
  setup(props, context) {
    let inDragZone = ref<boolean>(false);
    let uploadedFiles = ref<Array<Blob>>([]);

    watch(
      () => props.files,
      (newFiles) => {
        if (Array.isArray(newFiles)) {
          uploadedFiles.value = newFiles;
        } else if (newFiles instanceof Blob) {
          uploadedFiles.value[0] = newFiles;
        } else if (typeof newFiles === "undefined") {
          uploadedFiles.value = [];
        }
      }
    );

    const handleFileDrop = (event: any) => {
      if (props.multiple) {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          uploadedFiles.value.push(event.dataTransfer.files[i]);
          context.emit("update:files", uploadedFiles.value);
        }
      } else {
        uploadedFiles.value[0] = event.dataTransfer.files[0];
        context.emit("update:files", uploadedFiles.value[0]);
      }
    };

    const handleFilesUpload = (event: any) => {
      let requestFiles = event.target.files;
      if (props.multiple) {
        for (let i = 0; i < requestFiles.length; i++) {
          uploadedFiles.value.push(requestFiles[i]);
          context.emit("update:files", uploadedFiles.value);
        }
      } else {
        uploadedFiles.value[0] = requestFiles[0];
        context.emit("update:files", uploadedFiles.value[0]);
      }
    };

    const removeFile = (key: number) => {
      uploadedFiles.value.splice(key, 1);
      if (props.multiple) {
        context.emit("update:files", uploadedFiles.value);
      } else {
        context.emit("update:files", undefined);
      }
    };

    const invalid = computed(() => {
      return props.errors?.length !== 0;
    });

    return {
      inDragZone,
      uploadedFiles,
      handleFileDrop,
      handleFilesUpload,
      removeFile,
      determineFileType,
      invalid,
      randomKey: Math.random().toString(36),
    };
  },
});
